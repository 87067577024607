<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			indexSelected: 0,
			formData: {
               		// General
					adjustment_gre: 'NO',
					wl_level: '2',
					// WL Overlay
					// Input
					icr_rating: '8-3',
					icr_rating_score: 8.75,
					input_wl_level: 2,
					icr_rating_adjustment: '',
					icr_rating_comments: '',
					// Output
					overlay_rating: '8-3',
					overlay_rating_score: '8.75',
			},
		}
	},

	computed: {
		...mapGetters({
			'ratingSelected': 'arceRatings/selected'
		})
	},

	
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.multipleGeografia == 'NO') {
				let index = fields.indexOf('comentariosMultipleGeografia')
				fields.splice(index, 1)
			}
			if(this.form.multipleActividadEconomica == 'NO') {
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}
			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		save() {
			let vm = this
			let payload =  {
				key: 'overlays',
				form: {...this.formData},
				data: {},
			}
			vm.updateAnalysis(payload)
			this.hideModal('#formOverlaysModal')
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		hideModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).modal('hide')
			}
		},
		closeModal() {
			this.updateFormData()
		},
		updateFormData() {
			let formData = JSON.parse(JSON.stringify(this.ratingSelected.analysis.overlays.form))
			this.$set(this, 'formData', formData)
		},
		...mapMutations({
			updateAnalysis: 'arceRatings/updateAnalysis'
		}) 
		
	},
	mounted() {
		this.updateFormData()
		$('#formOverlaysModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formOverlaysModal').off('hide.bs.modal', this.closeModal)
	}
	
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
            <button class="s-btn s-btn--primary" @click="showModal('#formOverlaysModal')">
                Edit
            </button>
        </div>  
		<div  v-if="formData" class="py-3">
			
			
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_n_1" aria-expanded="true" aria-controls="c_o_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>General</span></h6>
				</div>
			</div>
			<div id="c_o_n_1" class="collapse show" aria-labelledby="c_o_n_1">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Adjustment GRE</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.adjustment_gre">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">WL level</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.wl_level">
					</div>
				</div>
               
			</div>
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_n_2" aria-expanded="true" aria-controls="c_o_n_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>WL Overlay</span></h6>
				</div>
			</div>
			<div id="c_o_n_2" class="collapse show" aria-labelledby="c_o_n_2">
				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_n_2_1" aria-expanded="true" aria-controls="c_o_n_2_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>INPUT</span></h6>
					</div>
				</div>
				<div id="c_o_n_2_1" class="collapse show" aria-labelledby="c_o_n_2_1">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">ICR Rating</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.icr_rating">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">ICR Rating Score</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.icr_rating_score">
						</div>
					</div>
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">WL Level</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.input_wl_level">
						</div>
					</div>
				</div>

				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_n_2_2" aria-expanded="true" aria-controls="c_o_n_2_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>OUTPUT</span></h6>
					</div>
				</div>
				<div id="c_o_n_2_2" class="collapse show" aria-labelledby="c_o_n_2_2">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">Overlay Rating</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.overlay_rating">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Overlay Rating Score</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.overlays.form.overlay_rating_score">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formOverlaysModal" tabindex="-1" role="dialog" aria-labelledby="formOverlaysModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div  v-if="formData" class="py-3">
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_m_1" aria-expanded="true" aria-controls="c_o_m_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>General</span></h6>
							</div>
						</div>
						<div id="c_o_m_1" class="collapse show" aria-labelledby="c_o_m_1">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Adjustment GRE</p>
									<input class="form-control " type="text" v-model="formData.adjustment_gre">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">WL level</p>
									<input class="form-control " type="text" v-model="formData.wl_level">
								</div>
							</div>
						
						</div>
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_m_2" aria-expanded="true" aria-controls="c_o_m_2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>WL Overlay</span></h6>
							</div>
						</div>
						<div id="c_o_m_2" class="collapse show" aria-labelledby="c_o_m_2">
							<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_m_2_1" aria-expanded="true" aria-controls="c_o_m_2_1">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>INPUT</span></h6>
								</div>
							</div>
							<div id="c_o_m_2_1" class="collapse show" aria-labelledby="c_o_m_2_1">
								<div class="d-flex flex-wrap">
									<div class="col-4 py-3">
										<p class="m-0">ICR Rating</p>
										<input class="form-control " type="text" v-model="formData.icr_rating">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">ICR Rating Score</p>
										<input class="form-control " type="text" v-model="formData.icr_rating_score">
									</div>
								</div>
								<div class="d-flex flex-wrap">
									<div class="col-4 py-3">
										<p class="m-0">WL Level</p>
										<input class="form-control " type="text" v-model="formData.input_wl_level">
									</div>
								</div>
							</div>

							<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_o_m_2_2" aria-expanded="true" aria-controls="c_o_m_2_2">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>OUTPUT</span></h6>
								</div>
							</div>
							<div id="c_o_m_2_2" class="collapse show" aria-labelledby="c_o_m_2_2">
								<div class="d-flex flex-wrap">
									<div class="col-4 py-3">
										<p class="m-0">Overlay Rating</p>
										<input class="form-control " type="text" v-model="formData.overlay_rating">
									</div>
									<div class="col-4 py-3">
										<p class="m-0">Overlay Rating Score</p>
										<input class="form-control " type="text" v-model="formData.overlay_rating_score">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.charts{
	max-height: 125px !important;
	min-height: 125px !important;
	height: 125px !important;
}
</style>